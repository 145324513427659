<template>
  <div class="page">
    <header>
      <van-nav-bar
        title="订单详情"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
      />
    </header>
    <section class="orderUser">
      <div class="box user">
        <div class="userAddTel">
          <span class="name">{{ sysOrderAddress.name}}</span>
          <span class="tel">{{ sysOrderAddress.phone }}</span>
          <span :class="sysOrderAddress.isDefault?'defaultFlag':'noDefault'">{{sysOrderAddress.isDefault?'默认':'其他'}}</span>
        </div>
        <div class="userAddress">
          <p>地址：{{ sysOrderAddress.address }}</p>
        </div>
      </div>
      <div class="box order">
        <div class="orderTitle">
          <div class="orderGroup">
            <label>订单号</label>
            <span>{{ orderDeatil.orderCode  }}</span>
          </div>
          <div class="status">{{ orderDeatil.orderStatusName }}</div>
        </div>
        <div class="good" v-for="(item, index) in orderDeatil.sysOrderDetails" :key="index">
                  <img :src="item.smallImagePath" alt="">
                  <div class="goodinfo">
                    <div class="goodname">
                      <label>
                        <span style="display: none">自营</span>
                        <span>{{item.productName}}</span>
                      </label>
                      <span class="goodNum">X{{ item.productNum }}</span>
                    </div>
                    <div class="shipping" v-show="orderDeatil.isShangpin">
                      <span>包邮</span>
                    </div>
                    <div class="price">
                      <span>￥</span>
                      <span>{{item.productPrice}}</span>
                    </div>
                    <div class="curStores" v-show="orderDeatil.isShangpin">
                      <span>{{orderDeatil.shopName}}</span>
                    </div>
                  </div>
        </div>

        <div class="cancelOrder" v-if="orderDeatil.orderStatusName == '待付款'">
          <van-button @click="cancelOrderInfo(orderDeatil.orderId,1)" plain round type="info" :style="{color: '#666',borderColor: '#d0d0d0',width:'8rem',height:'2.15rem', marginTop:'1.4rem'}">取消订单</van-button>
        </div>
        
      </div>
      <!-- 订单详情 -->
      <div class="box aboutOrder">
        <h3>订单详情</h3>
        <div class="orderCommon orderCode">
          <label>订单编号</label>
          {{ orderDeatil.orderCode }}
        </div>
        <div class="orderCommon orderTime">
          <label>订单时间</label>
          {{ orderDeatil.createTime }}
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import {queryOrderDetail,cancelOrderInfo} from "@/api/order";
  import axios from 'axios';
export default {
  data() {
    return {
      baseURL: axios.defaults.baseURL,
      orderDeatil: {
        isShangpin: false,
        sysOrderDetails:[],
      },
      cancelOrderInfoParam:{
        orderId:'',
        orderStatus:'',
        dr:''
      },
      sysOrderAddress:{
        name:'',
        phone:'',
        address:'',
        isDefault:'0'
      }
    };
  },
  mounted(){
    /*setTimeout(() => {
      this.userOrder = {
        userId:'汪晓晓',
        userTel:'13695874521',
        address:'湖北省襄阳市谷城区xxxxxxxxxx2栋2单元0123',
        orderid:'2365987452131411', 
        ordergoodUrl:require('@/assets/img/good.png'), 
        ordertitle:'XXXX商品名称',
        orderprice:'123',
        status: '待发货',
        isDefault:true,
        orderCode:'1234556677899094534231',
        time:'2024.03.15 15:00:00'
      }
    }, 1000);*/
  },
  created() {
    const orderCode = this.$route.query.orderCode;
    if(orderCode){
      this.queryOrderDetail(orderCode);
    }else{
      this.queryOrderDetail('20240403162425091055');
    }
  },
  methods: {
    async queryOrderDetail(code) {
      queryOrderDetail(code).then(response => {
        if(response.code==200){
          this.orderDeatil = response.data;
          if(response.data.sysOrderAddress){
            this.sysOrderAddress = response.data.sysOrderAddress
          }
          if(response.data.type==2){
            this.orderDeatil.isShangpin = true
          }else{
            this.orderDeatil.isShangpin = false
          }
          this.orderDeatil.sysOrderDetails.forEach((item) => {
            item.smallImagePath = this.baseURL + item.smallImagePath;
          })
        }else{
          this.$model({
            show: true,
            title: "提示",
            content: response.msg,
            confirmButton: false,
            cancelButton: true
          });
        }
      });
    },
    cancelOrderInfo(orderId,dr){
      this.cancelOrderInfoParam.orderId = orderId
      ////订单状态 1 待付款 2 待发货 3 已发货 4 待评价 0已完成 5已取消 6退款中 7退款成功 8退款异常
      this.cancelOrderInfoParam.orderStatus = 5
      this.cancelOrderInfoParam.dr = dr
      this.$model({
        show: true,
        title: "提示",
        content: '确认删除吗？',
        confirmButton: true,
        cancelButton: true,
        confirmText: '确认',
        cancelText: '取消',
        cancelCallBack: () => {},
        confirmCallBack: () => {
          cancelOrderInfo(this.cancelOrderInfoParam).then(response => {
            this.$model({
              show: true,
              title: "提示",
              content: response.msg,
              confirmButton: false,
              cancelButton: true
            });
            this.$router.push('/userOrder')
          });
        }
      });
    },
    // 返回
    onClickLeft(){
      this.$router.go(-1)
    },
  }
};
</script>
<style scoped lang="css">
* {
    padding: 0;
    margin: 0;
  }
  html,
  body {
    font-size: 37.5px;
    height: 100%;
  }
  .page{
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  header{
    height: 4rem;
    background-color: pink;
  }
  section{
    flex: 1;
  }
  .box{
    box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 1.46rem;
    background-color: #fff;
    margin-bottom: 1.06rem;
  }
  .orderUser{
    padding: 1.12rem 1.2rem;
    background-color: #f3f3f3;
  }
  .userAddTel{
    display: flex;
    align-items: center;
  }
  .userAddTel span.name{
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
  }
  .userAddTel span.tel{
    font-size: 0.9rem;
    color: #333;
    margin: 0 1.46rem;
  }
  .userAddTel span.defaultFlag{
    display: inline-block;
    padding: 0.13rem 0.32rem;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #fff;
    background-color: #fc7a3d;
  }
  .userAddress{
    margin-top: 1.12rem;
    font-size: 0.93rem;
    color: #666;
  }
  .order{

  }
  .orderTitle{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid #d5d5d5;
  }
  .orderTitle label{
    color: #a2a2a2;
    margin-right: 0.75rem;
  }
  .status{
    color: #0088fb;
    font-size: 1.12rem;
  }
  .cancelOrder{
    display: flex;
    justify-content: flex-end;
  }
  .aboutOrder h3{
    font-size: 1.12rem;
    margin-top: 1.12rem;
    font-weight: bold;
  }
  .orderCommon{
    line-height: 3.04rem;
    color: #666;
  }
  .orderCommon label{
    font-size: 1.12rem;
    color: #999;
    margin-right: 1.33rem;
  }
  /* copy */
.good{
  display: flex;
  padding: 1.2rem 0;
  border-bottom: 1px solid #f3f3f3;
}
.good img{
  width: 8rem;
  height: 5.867rem;
  border-radius: 20px;
  margin-right: 20px;
}
.goodinfo{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.goodname{
  padding: 0.21rem 0;
  display: flex;
  justify-content: space-between;
}
.goodname>label>span:nth-child(1){
  display: inline-block;
  padding: 3px 0.32rem;
  font-size: 0.693rem;
  line-height: 0.9rem;
  color:#fff;
  background-color: #fc7a3d;
  border-radius: 3px;
  margin-right: 0.48rem;
}
.goodname label span:nth-child(2){
  font-size: 0.8rem;
  color: #333;

}

.shipping span{
  display: inline-block;
  font-size: 0.64rem;
  padding: 0.13rem 0.32rem;
  color: #999;
  line-height: 0.9rem;
  margin: 0.25rem 0;
  border: 1px solid #c7c6c6;
  border-radius: 0.9rem ;
  box-sizing: border-box;
}
.price span{
  display: inline-block;
  color:#d81e06;
  font-weight: bold;
}
.price span:nth-child(1){
  font-size: 0.69rem;
}
.price span:nth-child(2){
  font-size: 1rem;
}
.curStores span {
  display: inline-block;
  line-height: 1.01rem;
  padding: 0.21rem 0.53rem;
  font-size: 0.64rem; 
  color:#bdbdbd;
  border-radius: 1.01rem;
  background-color: #f5f5f5;
}

</style>